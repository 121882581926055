<template>
    <div class="settings">
        <div class="head">
            <div class="back" @click="back()">
                <back :size="'20px'"/>
            </div>
            <div class="title">Post Settings</div>
        </div>
        <div class="main">
            <post_content :post="post" :blocked="false" :hidden="false" v-if="ready"/>
        </div>
        <div class="foot" v-if="ready">
            <div class="note">
                The new settings will reflect on next reload
            </div>
            <div class="item">
                <copy :size="'20px'"/>
                <div>
                    <div class="t">Copying</div>
                    <div class="label">Allow other users to copy this post</div>
                </div>
                <div class="switch">
                    <toggle-button :color="$root.$data.c.main" :labels="true" @change="changed()"
                    v-model="settings.copy"/>
                </div>
            </div>
            <div class="item">
                <comment :size="'20px'"/>
                <div>
                    <div class="t">Comment</div>
                    <div class="label">Allow comment feature on this post</div>
                </div>
                <div class="switch">
                    <toggle-button :color="$root.$data.c.main" :labels="true" @change="changed()"
                    v-model="settings.comment"/>
                </div>
            </div>
            <div class="item">
                <like :size="'20px'"/>
                <div>
                    <div class="t">Reactions</div>
                    <div class="label">Allow users to react on this post</div>
                </div>
                <div class="switch">
                    <toggle-button :color="$root.$data.c.main" :labels="true" @change="changed()"
                    v-model="settings.reaction"/>
                </div>
            </div>
            
            <loader :height="'35px'" v-if="loading"/>
            <div class="btn" @click="save()" v-else>Save</div>
        </div>
    </div>
</template>

<script>
import back from '../../icons/back.vue';
import post_content from '../../components/post/content.vue';
import loader from '../../components/loading.vue';

import { ToggleButton } from 'vue-js-toggle-button'

import copy from '../../icons/copy.vue';
import comment from '../../icons/comment.vue'
import like from '../../icons/liked.vue'

export default {
    components: {
        back,
        post_content,
        loader,
        ToggleButton,
        copy, comment, like,
    },
    mounted() {
        this.mount()
    },
    activated() {
        this.mount()
    },
    
    data() {
        return {
            post: {},
            ready: false,
            loading: false,
            
            settings: {
                copy: true,
                comment: true,
                reaction: true
            }
        }
    },
    methods: {
        mount() {
            this.post = JSON.parse(localStorage.getItem('settings'))
            
            if (this.post.settings) {
                for( let key in this.post.settings) {
                    this.settings[key] = this.post.settings[key]
                }
            }
            
            setTimeout(() => {
                this.ready = true
            }, 500);
        },
        back() {
            if (this.loading) { return }
            this.$router.go(-1)
        },
        save() {
            this.loading = true

            let form = new FormData()
            form.append('post_id', this.post.id)
            form.append('settings', JSON.stringify(this.settings))

            this.$http.post('/post/settings', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.saved()
                    

                    this.$router.go(-1)
                }
                this.loading = false
            })
        }
    },
    notifications: {
        saved: {
            type: 'success',
            title: 'Success',
            message: 'New settings will reflet on next reload'
        },
    }
}
</script>

<style scoped>

    .settings {
        height: 100vh;
        width: 100%;
        background-color: white;
    }
    .head {
        display: grid;
        grid-template-columns: calc(50% - 40px) 50%;
        align-items: center;
        padding: 15px 20px;
        border-bottom: 1px solid #ddd;
        fill: var(--main);
    }
    .title {
        font-weight: 500;
    }
    
    .main {
        height: calc(100vh - 303px);
        overflow: auto;
    }
    textarea {
        padding: 15px;
        width: 100%;
        box-sizing: border-box;
        box-sizing: border-box;
        border: none;
        outline: none;
        font-size: 16px;
    }


    .foot {
        border-top: 1px solid #ddd;
        padding: 15px;
        /* padding-top: 0px; */
    }
    .note {
        font-size: 12px;
        font-weight: 500;
        color: gray;
        margin-bottom: 10px;
    }
    
    .item {
        display: flex;
        align-items: center;
        gap: 20px;
        fill: #555;
        margin: 15px 0px;
    }
    .t {
        font-weight: 500;
        font-size: 14px;
    }
    .label {
        font-size: 12px;
        font-weight: 500;
        color: grey;
    }
    .switch {
        margin-left: auto;
    }

    .btn {
        font-weight: 500;
        font-size: 14px;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        padding: 8px;
        text-align: center;
    }
</style>